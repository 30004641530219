// prettier-ignore
import PropTypes from "prop-types";
import React from "react";
import { Route, Redirect } from "react-router-dom";

import Auth from "../utils/Auth";

const PrivateRoute = ({ children, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={() =>
      Auth.isAuthenticated() ? children : <Redirect to={{ pathname: "/" }} />
    }
  />
);

console.log("here in privateroute");

PrivateRoute.propTypes = {
  children: PropTypes.shape().isRequired,
};

export default PrivateRoute;
