// prettier-ignore
export default class Auth {
  static getAuth = () => localStorage.getItem("accessToken");

  static getAuthGroups = () => new Set(JSON.parse(window.atob((this.getAuth()).split('.')[1], "base64")).groups);

  static isAdmin = () => this.getAuthGroups().has("App.ElectronicJournal.ADMIN");

  static isAuthenticated = () => (new Date().getTime() < (JSON.parse(localStorage.getItem("expiresAt") * 1000)));
}
