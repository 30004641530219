'use-strict'

import axios from 'axios';
import axiosRetry from 'axios-retry';

export const getSuppliernetworkview = async () => {
  const params = {
    method: 'GET',
    url: process.env.REACT_APP_API_HOST
  }

  const client = axios.create();
  await retryRequest(client, 3);

  return client(params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(`error: ${error}`);
      return error.response.data;
    });
};

// export const getSupplierNetworkViewById = async (id) => {
//     console.log("getSupplierNetworkViewById")
// };

const retryRequest = async (client, retry) => {
  axiosRetry(client, {
    retries: retry,
    retryDelay: (count) => count * 1000, // time interval between retries
    shouldResetTimeout: true, // timeout after timeout_value on each retry.
    retryCondition: (error) => {
      // Retry Network Errors.
      if (axiosRetry.isNetworkError(error)) {
        return true
      }
      // Retry Server Errors (5xx).
      if (error.response.status >= 500 && error.response.status <= 599) {
        return true
      }
      // Cannot determine if the request can be retried
      if (!error.response) {
        return false
      }
    },
  });
}