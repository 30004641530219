import FeatureLayer from "@arcgis/core/layers/FeatureLayer";



const clusterConfig = {
  type: "cluster",
  clusterRadius: "100px",
  popupTemplate: {
    title: "Nike Supply Chain",
    content: "This point represents {cluster_count} suppliers.",
    fieldInfos: [
      {
        fieldName: "cluster_count",
        format: {
          places: 0,
          digitSeparator: true
        }
      }
    ]
  },
  clusterMinSize: "24px",
  clusterMaxSize: "60px",

  symbol: {
    type: "simple-marker",
    style: "circle",
    color: "#69dcff",
    outline: {
      color: "rgba(0, 139, 174, 0.5)",
      width: 6
    }
  },

  labelingInfo: [
    {
      deconflictionStrategy: "none",
      labelExpressionInfo: {
        expression: "Text($feature.cluster_count, '#,###')"
      },
      symbol: {
        type: "text",
        color: "#004a5d",
        font: {
          weight: "bold",
          family: "Noto Sans",
          size: "12px"
        }
      },
      labelPlacement: "center-center"
    }
  ]
};




function popupTitle(feature) {
    return '<img border="0" class="popup-nike-logo" src="https://ok4static.oktacdn.com/fs/bco/1/fs0piw5qn8xZkWS4q1t6">{supplierName}';
  }
  
   const suppliersLayer = new FeatureLayer({
    title: 'Nike Supply Chain',
    source: [],
    geometryType: "point",
    objectIdField: "supplierId",
    fields: [
      {
        name: "supplierId",
        alias: "supplierId",
        type: "oid"
      },
      {
        name: "productEngine",
        alias: "productEngine",
        type: "string"
      },
      {
        name: "supplierName",
        alias: "supplierName",
        type: "string"
      },
      {
        name: "supplierCity",
        alias: "supplierCity",
        type: "string"
      },
      {
        name: "supplierCountry",
        alias: "supplierCountry",
        type: "string"
      },
      {
        name: "workers",
        alias: "workers",
        type: "integer"
      },
      {
        name: "femaleWorkers",
        alias: "femaleWorkers",
        type: "integer"
      },
      {
        name: "migrantWorkers",
        alias: "migrantWorkers",
        type: "integer"
      },
      {
        name: "averageWorkerAge",
        alias: "averageWorkerAge",
        type: "integer"
      },
      {
        name: "tier",
        alias: "tier",
        type: "string"
      },
      {
        name: "brand",
        alias: "brand",
        type: "string"
      }
    ],
    popupTemplate: {
      // title: "{supplierName}",
      title: popupTitle,
      outFields: ["*"],
      content: [
        {
          type: "text",
          text: "<p>{supplierCity}, {supplierCountry} - <span class='popup-product-engine'>{productEngine}</span> - <span class='popup-tier'>{tier}</span></p>"
        },
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "workers",
              label: "Workers",
              "format": {
                digitSeparator: true,
                places: 0
              }
            },
            {
              fieldName: "femaleWorkers",
              label: "Female workers",
              "format": {
                digitSeparator: true,
                places: 0
              }
            },
            {
              fieldName: "migrantWorkers",
              label: "Migrant workers",
              "format": {
                digitSeparator: true,
                places: 0
              }
            },
            {
              fieldName: "averageWorkerAge",
              label: "Average worker age",
              "format": {
                digitSeparator: true,
                places: 0
              }
            }
          ]
        }
      ]
    },

    renderer: {
      type: "unique-value",
      legendOptions: {
        title: "Product Engine"
      },
      field: "productEngine",
      uniqueValueInfos: [
        {
          value: "Footwear",
          label: "Footwear",
          symbol: {
            type: "simple-marker",
            size: 6,
            color: [255, 128, 0],
            outline: null
          }
        },
        {
          value: "Apparel",
          label: "Apparel",
          symbol: {
            type: "simple-marker",
            size: 6,
            color: [0, 225, 128],
            outline: null
          }
        },
        {
          value: "Equipment",
          label: "Equipment",
          symbol: {
            type: "simple-marker",
            size: 6,
            color: [128, 0, 255],
            outline: null
          }
        },
      ]
    },
    featureReduction :clusterConfig
    
  });

  

  export default suppliersLayer