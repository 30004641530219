import React from "react";
import styled from "styled-components/macro";
// import { Link } from "react-router-dom";

import { Helmet } from "react-helmet-async";
// import { Button as MuiButton, Typography } from "@material-ui/core";
// import { spacing } from "@material-ui/system";
import "../../styles/invalid-email.css";

// const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function InvalidEmail() {
  return (
    <div className="invalid-email">
      <Wrapper>
        <Helmet title="404 Error" />
         Unauthorized You dont have necessary permission to access this page
        {/* <Typography component="h1" variant="h1" align="center" gutterBottom>
          Unauthorized
        </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          You dont have necessary permission to access this page
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          align="center"
          gutterBottom
        ></Typography> */}

        {/* <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          mt={2}
        >
          Request for permission
        </Button> */}
      </Wrapper>
    </div>
  );
}

export default InvalidEmail;
